<template>
  <!-- 分账账单管理9.0.0 -->
  <div class="divisionBill-manage-page" ref="divisionBillManage">
    <el-tabs type="border-card" @tab-click="tabClickFun" v-model="activeName">
      <el-tab-pane
        v-for="(item, index) in listData"
        :key="index"
        :name="item.value"
        :label="item.label"
        class="el-tab-pane1"
      >
      </el-tab-pane>
      <div ref="form" class="form-area">
        <el-form
          :inline="true"
          :model="searchForm"
          label-position="left"
          label-width="96px"
          class="demo-form-inline"
        >
          <el-form-item label="分账周期：" class="code-time-range">
            <el-date-picker
              v-model="period"
              type="daterange"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :clearable="false"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="所属公司：" prop="companyId">
            <companySelectForSearch
              ref="getGroupIdRef"
              :searchable="true"
              @getValue="getGroupId"
            ></companySelectForSearch>
          </el-form-item>
          <el-form-item label="接收方：">
            <el-select
              v-model="searchForm.receiveMchId"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in receiverOptions"
                :key="item.ledgerReceiveId"
                :label="item.ledgerReceiveName"
                :value="item.ledgerReceiveId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务类型：">
            <el-select v-model="searchForm.businessType" placeholder="请选择">
              <el-option
                v-for="item in businessTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeName != 'all'" label="平台交易号：">
            <el-input
              v-model="searchForm.transactionId"
              type="input"
              clearable
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="activeName != 'all'" label="交易状态：">
            <el-select
              v-model="searchForm.transactionStatus"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeName != 'all'" label="交易类型：">
            <el-select
              v-model="searchForm.ledgerType"
              placeholder="请选择"
              clearable
            >
              <el-option label="分账" :value="0"> </el-option>
              <el-option label="分账回退" :value="1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="onSearch"
              >查询</el-button
            >
            <el-button type="primary" size="small" @click="onExport"
              >导出</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div ref="tips" v-show="activeName === 'all'" class="tips">
        实分金额：{{ peice }}元
      </div>
      <Table
        :table-data="tableData"
        :titleName="activeName == 'all' ? titleName : titleNameDetail"
        :col-class-type="true"
        :table-height="tableHeight"
      ></Table>
      <div ref="pagination" class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="searchForm.pageSize"
          :current-page="searchForm.currentPage"
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        ></el-pagination>
      </div>
    </el-tabs>
  </div>
</template>
<script>
import {
  ledgerDetialPage,
  ledgerBillPageExport,
  ledgerDetialPageExport,
  ledgerBillPage,
  listRedisCache,
} from "@/api/lib/api.js";
import { expXlsx } from "@/tools/tools";
import moment from "moment";
export default {
  data() {
    return {
      activeName: "all",
      peice: 0,
      total: 0,
      listData: [
        {
          label: "分账账单",
          value: "all",
        },
        {
          label: "分账明细",
          value: "detail",
        },
      ],
      period: null,
      searchForm: {
        startDay: null,
        endDay: null,
        companyId: null,
        receiveMchId: null,
        businessType: null,
        transactionId: null,
        transactionStatus: null,
        ledgerType: null,
        pageSize: 10,
        currentPage: 1,
      },
      businessTypeList: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 1,
          label: "定制客运",
        },
        {
          value: 4,
          label: "包车",
        },
      ],
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      statusOptions: [
        {
          label: "处理中",
          value: 0,
        },
        {
          label: "成功",
          value: 1,
        },
        {
          label: "失败",
          value: 2,
        },
      ],
      receiverOptions: [],
      tableData: [],
      titleName: [
        {
          title: "分账周期",
          SpecialJudgment: (res) =>
            moment(new Date(res.startDay * 1000)).format("YYYY-MM-DD") +
            " - " +
            moment(new Date(res.endDay * 1000)).format("YYYY-MM-DD"),
          width: 200,
        },
        {
          title: "所属公司",
          props: "companyName",
        },
        {
          title: "接收方",
          props: "receiveMchName",
        },
        {
          title: "分账金额",
          props: "ledgerAmount",
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "回退金额",
          props: "ledgerRefundAmount",
          width: 150,
          SpecialJudgment: (res) => res / 100,
        },
        {
          title: "实分金额",
          props: "amount",
          SpecialJudgment: (res) => res / 100,
        },
      ],
      titleNameDetail: [
        {
          title: "交易时间",
          props: "startTime",
          width: 160,
        },
        {
          title: "分账/回退金额",
          render: (h, res, index) => {
            return h(
              "div",
              {
                style: { color: res.ledgerType ? "red" : "green" },
              },
              res.ledgerType
                ? "-" + res.transactionPrices / 100
                : "+" + res.transactionPrices / 100
            );
          },
        },
        {
          title: "交易状态",
          props: "transactionStatus",
          SpecialJudgment: (res) => {
            return res == 1 ? "成功" : res == 2 ? "失败" : "处理中";
          },
        },
        {
          title: "交易类型",
          props: "ledgerType",
          SpecialJudgment: (res) => (res ? "分账回退" : "分账"),
        },
        {
          title: "订单支付/退款金额",
          width: 150,
          render: (h, res, index) => {
            return h(
              "div",
              {
                style: { color: res.ledgerType ? "red" : "green" },
              },
              res.ledgerType
                ? "-" + res.orderAmount / 100
                : "+" + res.orderAmount / 100
            );
          },
        },

        {
          title: "分账比例/金额",
          SpecialJudgment: (res) => {
            if (res.ledgerReceiveValue) {
              return res.ledgerReceiveModel == 1
                ? res.ledgerReceiveValue + "%"
                : res.ledgerReceiveValue / 100 + "元";
            } else return "-";
          },
        },
        {
          title: "平台交易单号",
          props: "transactionId",
          SpecialJudgment: (res) => (res ? res : "-"),
        },
        {
          title: "第三方交易单号",
          width: 130,
          props: "outTransactionId",
          SpecialJudgment: (res) => (res ? res : "-"),
        },
        {
          title: "所属公司",
          props: "companyName",
        },
        {
          title: "分账方",
          SpecialJudgment: (res) => res.ledgerMchName + "-" + res.ledgerMchId,
        },
        {
          title: "接收方",
          SpecialJudgment: (res) => res.receiveMchName + "-" + res.receiveMchId,
        },
      ],
      tableHeight: 0,
    };
  },
  mounted() {
    this.period = this.resetTime();
    this.queryReceiveList();
    this.onSearch();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    queryReceiveList() {
      listRedisCache().then((res) => {
        if (res.code == "SUCCESS") {
          this.receiverOptions = res.data;
        }
      });
    },
    computeHeight() {
      const wholeHeight = this.$refs.divisionBillManage.clientHeight;
      const tips = this.$refs.tips.clientHeight;
      const form = this.$refs.form.clientHeight;
      const pagination = this.$refs.pagination.clientHeight;
      this.tableHeight = wholeHeight - tips - form - pagination - 140;
    },
    resetTime() {
      const date1 =
        moment().subtract(1, "months").add(1, "day").format("YYYY-MM-DD ") +
        "00:00:00";
      const date2 = moment().format("YYYY-MM-DD ") + "23:59:59";
      return [date1, date2];
    },
    getGroupId(v) {
      if (v == null) {
        this.searchForm.companyId = null;
      } else {
        this.searchForm.companyId = v;
      }
    },
    onSizeChange(size) {
      this.searchForm.pageSize = size;
      this.searchForm.currentPage = 1;
      this.onSearch();
    },
    onCurrentChange(page) {
      this.searchForm.currentPage = page;
      this.onSearch();
    },
    onExport() {
      this.searchForm.startDay = new Date(this.period[0]).getTime();
      this.searchForm.endDay = new Date(this.period[1]).getTime();
      let API = "";
      let name = "";
      if (this.activeName == "all") {
        API = ledgerBillPageExport;
        name = "分账账单";
      } else {
        API = ledgerDetialPageExport;
        name = "分账明细";
      }
      API(this.searchForm).then((res) => {
        expXlsx(res, name);
      });
    },
    onSearch() {
      this.searchForm.startDay = new Date(this.period[0]).getTime();
      this.searchForm.endDay = new Date(this.period[1]).getTime();
      if (this.activeName == "all") {
        ledgerBillPage(this.searchForm).then((res) => {
          if (res.code == "SUCCESS") {
            this.tableData = res.data.list;
            this.total = res.data.total;
            this.peice = res.data.value / 100;
          }
        });
      } else {
        ledgerDetialPage(this.searchForm).then((res) => {
          if (res.code == "SUCCESS") {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        });
      }
    },
    tabClickFun() {
      this.period = this.resetTime();
      this.$refs.getGroupIdRef.resetForm();
      this.searchForm = {
        startDay: new Date(this.period[0]).getTime(),
        endDay: new Date(this.period[1]).getTime(),
        companyId: null,
        receiveMchId: null,
        businessType: null,
        transactionId: null,
        transactionStatus: null,
        ledgerType: null,
        pageSize: 10,
        currentPage: 1,
      };
      this.onSearch();
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>
<style lang="scss" scoped>
.divisionBill-manage-page {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  .tips {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .form-area {
    margin-bottom: 12px;
  }
  .el-tabs {
    height: 100%;
    .el-tab-pane {
      height: 100%;
    }
  }
  /deep/.code-time-range {
    .el-date-editor {
      width: 13vw !important;
      .el-range-separator {
        line-height: 24px;
      }
    }
  }
}
</style>
